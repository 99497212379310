.left-panel {
    height: 100vh;
    background-color: white;

    .login-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        .login-header {
            display: flex;
            flex-direction: column;
            align-items: center;

            .logo {
                img {
                    height: 100px;
                    width: 120px;
                    margin: 10px;
                }

                h1 {
                    font-size: 20px;
                    opacity: 0.8;
                }
            }

            span {
                margin: 10px;
                font-weight: bold;
            }
        }

        .login-error-message {
            text-align: center;
            color: red;
            font-style: italic;
        }

        .login-form {
            min-height: 200px;
            ;
            min-width: 350px;
            padding: 10px;
            align-self: center;

            .title-header {
                text-align: center;

                h1 {
                    font-size: 42px;
                    font-weight: bold;
                }
            }

            h4 {
                font-weight: bold;
            }

            .login-reminder {
                text-align: center;

                p {
                    font-size: 10px;
                    color: #708791;
                }
            }


        }
    }
}

.right-panel {
    height: 100vh;
    // background-image: url('../../../../assets/images/wallpaperLoginClient.jpg');
    // background-image: url('https://designclinic.sg/wp-content/uploads/2017/02/Untitled-design-21.png');
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 255, 0.4);

    display: flex;
    justify-content: center;
    align-items: center;

    .website-name {
        text-align: center;

        h1 {
            color: white;
            font-size: 52px;
        }

        p {
            color: white;
            font-size: 18px;
            opacity: 0.9;
        }
    }
}

@media screen and (max-width: 991px) {
    .right-panel {
        display: none !important;
    }
}

.terms-and-conditions {
    .title {
        margin: 10px 0;
        text-align: center;
    }

    p {
        text-align: justify;
        font-family: Arial, Helvetica, sans-serif;
        text-indent: 25px;
    }
}